import Config from './config';

const URLS = {
  root: '/',
  documentEdit: (documentId = ':documentId') => `/documents/${documentId}/edit`,
  documents: `/documents/`,
  myDocuments: `/documents/my/`,
  sharedDocuments: `/documents/shared/`,
  trashDocuments: `/documents/trash/`,
  myPresentations: `/presentations/my/`,
  sharedPresentations: `/presentations/shared/`,
  trashPresentations: `/presentations/trash/`,
  documentHistory: (documentId = ':documentId') => `/documents/${documentId}/history/`,
  documentRevisions: (documentId = ':documentId') => `/documents/${documentId}/revisions/`,
  documentRevision: (revisionId = ':revisionId') => `/documents/revisions/${revisionId}/`,
  references: `/references/`,
  referencesAdd: `/references/add`,
  referencesEdit: (referenceId = ':referenceId') => `/references/${referenceId}/edit`,
  referencesPDF: (referenceId = ':referenceId') => `/references/${referenceId}/pdf`,
  fastref: `/references/fastref`,
  modelos: `/modelos/`,
  templates: `/templates/`,
  documentTemplates: `/templates/?template_type=document`,
  presentationTemplates: `/templates/?venue=presentation`,
  // Advisory
  advisoryProducts: '/advisory/products/',
  advisoryCourses: '/advisory/courses/',
  advisoryLeads: '/advisory/leads/',
  advisoryAdvisers: '/advisory/advisers/',
  advisoryLeadForm: '/advisory/new/',
  advisoryInvoice: (invoiceId = ':invoiceId') => `/advisory/invoice/${invoiceId}/`,
  // Accounts
  account: `/account/`,
  billing: `/billing/`,
  paymentConfirmation: '/billing/payment-confirmation',
  plans: `/plans/`,
  pay: (planId = ':planId') => `/plans/${planId}/pay`,
  wholesalePlan: (planId = ':planId') => `/wholesales/${planId}`,
  inviting: `/inviting/`,
  admin: `${Config.djangoHost}_ff/`,
  mailSubscriber: '/admin/mailing/subscriber/',
  mailSubscriberProfessor: '/admin/mailing/subscriberProfessor/',
  // Reports
  reportUsers: '/reports/users',
  reportInstitutions: '/reports/institutions',
  reportDocuments: `${Config.djangoHost}r/documents`,
  reportCheckouts: '/reports/checkouts',
  reportAreas: '/reports/knowledge-areas',
  reportPaidDocuments: '/reports/paid-doc-statistics',
  // Helps
  videos: `/help/videos`,
  faq: `/help/faq`,
  about: `/about`,
  terms: `/tos`,
  privacy: `/privacy`,
  blog: '//blog.fastformat.co',
};

export default URLS;
