import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import '../vendor/bv-1.4.2/lib/bootstrap/dist/css/bootstrap.css';
import '../vendor/bv-1.4.2/lib/material-design-icons/css/material-design-iconic-font.css';
import '../vendor/bv-1.4.2/lib/perfect-scrollbar/css/perfect-scrollbar.css';
import '../vendor/bv-1.4.2/css/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './custom.css';

import '../vendor/bv-1.4.2/lib/perfect-scrollbar/js/perfect-scrollbar.jquery';
import '../vendor/bv-1.4.2/lib/bootstrap/dist/js/bootstrap';

import Urls from './urls';
import LoadingPage from './common/components/Loading';
import { logError } from './common/ajax';
import { getUpdatePermissions } from './context/global';

/* Code splitting */
const TemplateListPage = lazy(() => import('./templates/TemplateListPage' /* webpackChunkName: "templates" */));
const DocumentHistoryPage = lazy(() => import('./documents/DocumentHistoryPage' /* webpackChunkName: "history" */));
const ReferenceListPage = lazy(() => import('./references/ReferenceListPage' /* webpackChunkName: "references" */));
const DocumentRevisionList = lazy(() => import('./documents/DocumentRevisionList' /* webpackChunkName: "revisions" */));
const DocumentRevision = lazy(() => import('./documents/DocumentRevision' /* webpackChunkName: "revision" */));
const ReferencePDFViewer = lazy(() => import('./references/ReferencePDFViewer' /* webpackChunkName: "refrence-pdf" */));
const ProfilePage = lazy(() => import('./account/profile/ProfilePage' /* webpackChunkName: "profile" */));
const BillingPage = lazy(() => import('./sales/billing/BillingPage' /* webpackChunkName: "billing" */));
const PlanWholesalePage = lazy(() => import('./sales/plans/PlanWholesalePage' /* webpackChunkName: "wholesales" */));

const EditorPage = lazy(() => {
  const scripts = [
    '/lib/jquery-1.12.4.min.js',
    '/lib/withinviewport.js',
    '/lib/jquery.withinviewport.js',
    '/lib/ckeditor/formula/formulas.js',
    '/lib/ckeditor/ckeditor_4.6.0/ckeditor.js',
  ];
  scripts.forEach((scriptSrc) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptSrc;
    script.async = false;
    window.document.head.appendChild(script);
  });
  return import('./editor/EditorPage' /* webpackChunkName: "editor" */);
});

const FastrefPage = lazy(() => import('./fastref/FastrefPage' /* webpackChunkName: "fastref" */));
const VideosPage = lazy(() => import('./help/VideosPage' /* webpackChunkName: "videos" */));
const FaqPage = lazy(() => import('./help/FaqPage' /* webpackChunkName: "faq" */));
const PlansPage = lazy(() => import('./sales/plans/PlansPage' /* webpackChunkName: "plans" */));
const PayPage = lazy(() => import('./sales/payment/PayPage' /* webpackChunkName: "pay" */));
const PaymentConfirmationPage = lazy(() =>
  import('./sales/payment/PaymentConfirmationPage' /* webpackChunkName: "PaymentConfirmationPage" */),
);
const DocumentsPage = lazy(() => import('./documents/DocumentsPage' /* webpackChunkName: "documents" */));
const PageErrorBoundary = lazy(() => import('./common/containers/PageErrorBoundary' /* webpackChunkName: "error" */));
const InvitingPage = lazy(() => import('./inviting/InvitingPage' /* webpackChunkName: "inviting" */));
const AboutPage = lazy(() => import('./legal/AboutPage' /* webpackChunkName: "about" */));
const TermsOfServicePage = lazy(() => import('./legal/TermsOfServicePage' /* webpackChunkName: "tos" */));
const Privacy = lazy(() => import('./legal/Privacy' /* webpackChunkName: "privacy" */));

const CheckoutsReportPage = lazy(() =>
  import('./admin/reports/CheckoutsReportPage' /* webpackChunkName: "reports-checkouts" */),
);
const UsersReportPage = lazy(() => import('./admin/reports/UsersReportPage' /* webpackChunkName: "reports-users" */));
const InstitutionsReportPage = lazy(() =>
  import('./admin/reports/InstitutionsReportPage' /* webpackChunkName: "reports-institutions" */),
);
const PaidDocumentsReportPage = lazy(() =>
  import('./admin/reports/PaidDocumentsReportPage' /* webpackChunkName: "reports-paid-documents" */),
);
const KnowledgeAreasReportPage = lazy(() =>
  import('./admin/reports/KnowledgeAreasReportPage' /* webpackChunkName: "knowledge-areas-documents" */),
);

const AdvisoryLeadPage = lazy(() =>
  import('./advisory/AdvisoryLeadPage' /* webpackChunkName: "advisory-leadformpage" */),
);
const AdvisoryProductsPage = lazy(() =>
  import('./advisory/services/AdvisoryServicesPage' /* webpackChunkName: "advisory-productspage" */),
);
const AdvisoryInvoicePage = lazy(() =>
  import('./advisory/services/AdvisoryInvoicePage' /* webpackChunkName: "advisory-confirminvoicepage" */),
);
const AdvisoryCoursesPage = lazy(() =>
  import('./advisory/services/AdvisoryCoursesPage' /* webpackChunkName: "advisory-coursespage" */),
);

const MailSubscribePage = lazy(() =>
  import('./admin/mailing/MailSubscribePage' /* webpackChunkName: "admin-MailSubscribePage" */),
);
const SubscribeProfessorPage = lazy(() =>
  import('./admin/mailing/SubscribeProfessorPage' /* webpackChunkName: "admin-SubscribeProfessorPage" */),
);
/* End of code splitting */

window.onerror = (msg, file, line, col, error) => {
  const perms = getUpdatePermissions();
  logError(perms && perms.user, error);
};

function wrapError(component, props) {
  return <PageErrorBoundary>{React.createElement(component, props)}</PageErrorBoundary>;
}

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { i18n } = this.props;
    return (
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route exact path={Urls.root}>
              <Redirect to={Urls.myDocuments} />
            </Route>
            /* Documents */
            <Route exact path={Urls.documents} render={(props) => wrapError(DocumentsPage, props)} />
            <Route
              path={Urls.myDocuments}
              render={(props) =>
                wrapError(DocumentsPage, {
                  ...props,
                  category: 'my',
                  type: 'documents',
                  title: i18n.t('Meus documentos'),
                })
              }
            />
            <Route
              path={Urls.sharedDocuments}
              render={(props) =>
                wrapError(DocumentsPage, {
                  ...props,
                  category: 'shared',
                  type: 'documents',
                  title: i18n.t('Documentos compartilhados comigo'),
                })
              }
            />
            <Route
              path={Urls.trashDocuments}
              render={(props) =>
                wrapError(DocumentsPage, {
                  ...props,
                  category: 'trash',
                  type: 'documents',
                  title: i18n.t('Documentos na lixeira'),
                })
              }
            />
            <Route
              path={Urls.myPresentations}
              render={(props) =>
                wrapError(DocumentsPage, {
                  ...props,
                  category: 'my',
                  type: 'presentations',
                  title: i18n.t('Minhas apresentações'),
                })
              }
            />
            <Route
              path={Urls.sharedPresentations}
              render={(props) =>
                wrapError(DocumentsPage, {
                  ...props,
                  category: 'shared',
                  type: 'presentations',
                  title: i18n.t('Apresentações compartilhadas comigo'),
                })
              }
            />
            <Route
              path={Urls.trashPresentations}
              render={(props) =>
                wrapError(DocumentsPage, {
                  ...props,
                  category: 'trash',
                  type: 'presentations',
                  title: i18n.t('Apresentações na lixeira'),
                })
              }
            />
            <Route exact path={Urls.documentHistory()} render={(props) => wrapError(DocumentHistoryPage, props)} />
            <Route exact path={Urls.documentRevisions()} render={(props) => wrapError(DocumentRevisionList, props)} />
            <Route exact path={Urls.documentRevision()} render={(props) => wrapError(DocumentRevision, props)} />
            <Route exact path={Urls.documentEdit()} render={(props) => wrapError(EditorPage, props)} />
            /* References */
            <Route exact path={Urls.references} render={(props) => wrapError(ReferenceListPage, props)} />
            {/*<Route path={Urls.referencesAdd} render={ReferenceFormPage} />*/}
            {/*<Route path={Urls.referencesEdit()} component={ReferenceFormPage} />*/}
            <Route exact path={Urls.referencesPDF()} render={(props) => wrapError(ReferencePDFViewer, props)} />
            <Route exact path={Urls.fastref} render={(props) => wrapError(FastrefPage, props)} />
            /* Templates */
            <Route exact path={Urls.modelos} render={(props) => wrapError(TemplateListPage, props)} />
            <Route exact path={Urls.modelos + ':alias'} render={(props) => wrapError(TemplateListPage, props)} />
            <Route exact path={Urls.templates} render={(props) => wrapError(TemplateListPage, props)} />
            <Route exact path={Urls.templates + ':alias'} render={(props) => wrapError(TemplateListPage, props)} />
            <Route
              exact
              path={Urls.presentationTemplates}
              render={(props) => wrapError(TemplateListPage, { ...props, title: i18n.t('Modelos de apresentações') })}
            />
            /* Account */
            <Route exact path={Urls.account} render={(props) => wrapError(ProfilePage, props)} />
            <Route exact path={Urls.billing} render={(props) => wrapError(BillingPage, props)} />
            /* Billing */
            <Route exact path={Urls.plans} render={(props) => wrapError(PlansPage, props)} />
            <Route exact path={Urls.pay()} render={(props) => wrapError(PayPage, props)} />
            <Route
              exact
              path={Urls.paymentConfirmation}
              render={(props) => wrapError(PaymentConfirmationPage, props)}
            />
            <Route exact path={Urls.wholesalePlan()} render={(props) => wrapError(PlanWholesalePage, props)} />
            /* Inviting */
            <Route exact path={Urls.inviting} render={(props) => wrapError(InvitingPage, props)} />
            /* Help */
            <Route exact path={Urls.videos} render={(props) => wrapError(VideosPage, props)} />
            <Route exact path={Urls.faq} render={(props) => wrapError(FaqPage, props)} />
            /* Legal */
            <Route exact path={Urls.about} render={(props) => wrapError(AboutPage, props)} />
            <Route exact path={Urls.terms} render={(props) => wrapError(TermsOfServicePage, props)} />
            <Route exact path={Urls.privacy} render={(props) => wrapError(Privacy, props)} />
            /* Reports */
            <Route exact path={Urls.reportCheckouts} render={(props) => wrapError(CheckoutsReportPage, props)} />
            <Route exact path={Urls.reportUsers} render={(props) => wrapError(UsersReportPage, props)} />
            <Route exact path={Urls.reportInstitutions} render={(props) => wrapError(InstitutionsReportPage, props)} />
            <Route
              exact
              path={Urls.reportPaidDocuments}
              render={(props) => wrapError(PaidDocumentsReportPage, props)}
            />
            <Route exact path={Urls.reportAreas} render={(props) => wrapError(KnowledgeAreasReportPage, props)} />
            /* Advisory */
            <Route exact path={Urls.advisoryProducts} render={(props) => wrapError(AdvisoryProductsPage, props)} />
            <Route exact path={Urls.advisoryLeadForm} render={(props) => wrapError(AdvisoryLeadPage, props)} />
            <Route exact path={Urls.advisoryInvoice()} render={(props) => wrapError(AdvisoryInvoicePage, props)} />
            <Route exact path={Urls.advisoryCourses} render={(props) => wrapError(AdvisoryCoursesPage, props)} />
            /* Admin */
            <Route exact path={Urls.mailSubscriber} render={(props) => wrapError(MailSubscribePage, props)} />
            <Route
              exact
              path={Urls.mailSubscriberProfessor}
              render={(props) => wrapError(SubscribeProfessorPage, props)}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default withNamespaces()(App);
