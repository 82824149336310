import ExpireStorage from 'expired-storage';
import Cookie from "js-cookie";

class Storage {
    constructor() {
        this.expireStorage = new ExpireStorage();
    }

    getItem(key, defaultValue) {
        let value = localStorage.getItem(key);

        if (value === null || this.expireStorage.isExpired(key)) {
            return defaultValue;
        }

        return value;
    }

    setItem(key, value, secondsToExpire) {
        try {
            if (secondsToExpire) {
                this.expireStorage.setItem(key, value, secondsToExpire);
            } else {
                localStorage.setItem(key, value);
            }
        } catch (e) {
            console.error(e);
        }
    }

    removeItem(key) {
        localStorage.removeItem(key);
        this.expireStorage.removeItem(key);
    }

    isExpired(key) {
        return this.expireStorage.isExpired(key);
    }

    getCookie(key, defaultValue) {
        return Cookie.get(key, defaultValue);
    }
}

const storage = new Storage();

export default storage;