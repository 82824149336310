import storage from "./storage";
import Config from "../config";

const Ajax = {
    get: function (url, fields) {
        return this._get(url, fields, true);
    },

    getSync: function(url, fields) {
        return this._get(url, fields, false);
    },

    getWithoutCredentials: function(url, fields) {
        return this._get(url, fields, true, false);
    },

    post: function (url, data) {
        return this._postJSON(url, data, true);
    },

    postJSON: function (url, data) {
        return this._postJSON(url, data, true);
    },

    postJSONSync: function (url, data) {
        return this._postJSON(url, data, false);
    },

    postFormData: function (url, data, async = true) {
        return $.ajax({
            url: url,
            method: 'POST',
            async: async,
            processData: false,
            data: data,
            contentType: false,
            xhrFields: { withCredentials: true }
        });
    },

    put: function (url, data) {
        return this._put(url, data, true);
    },

    patch: function (url, data) {
        return this._patch(url, data, true);
    },

    patchSync: function (url, data) {
        return this._patch(url, data, false);
    },

    postSync: function(url, data) {
        return this._postJSON(url, data, false);
    },

    delete_: function (url, data) {
        return this._delete(url, data, true);
    },

    deleteSync: function(url, data) {
        return this._delete(url, data, false);
    },

    _get: function (url, fields, async = true, withCredentials = true) {
        if (fields && url.indexOf('only=') === -1) {
            if (url.indexOf('?') > -1) {
                url += '&only=' + fields;
            } else {
                url += '?only=' + fields;
            }
        }

        return $.ajax({
            url: url,
            method: 'GET',
            async,
            xhrFields: { withCredentials }
        });
    },

    _post: function (url, data, async = true) {
        return $.ajax({
            url: url,
            method: 'POST',
            async: async,
            data: data,
            xhrFields: { withCredentials: true }
        });
    },

    _postJSON: function (url, data, async = true) {
        return $.ajax({
            url: url,
            method: 'POST',
            async: async,
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            processData: false,
            xhrFields: { withCredentials: true }
        });
    },

    _put: function (url, data, async = true) {
        return $.ajax({
            url: url,
            method: 'PUT',
            async: async,
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            xhrFields: { withCredentials: true }
        });
    },

    _patch: function (url, data, async = true) {
        return $.ajax({
            url: url,
            method: 'PATCH',
            async: async,
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            processData: false,
            xhrFields: { withCredentials: true }
        });
    },

    _delete: function (url, data, async = true) {
        return $.ajax({
            url: url,
            method: 'DELETE',
            async: async,
            data: data,
            xhrFields: { withCredentials: true }
        });
    },
};

export default Ajax;

(function () {
    "use strict";

    const tokenName = 'csrftoken2';

    if (!storage.getCookie(tokenName)) {
        Ajax.getSync(`${Config.apiHost}ensure-csrf-token/`);
    }

    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }

    $(document).ajaxSend(function (event, xhr, settings) {
        xhr.xhrFields = { withCredentials: true };
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", storage.getCookie(tokenName));
        }
    });
})();

export function logError(user, error) {
    import('stacktrace-js' /* webpackChunkName: 'stracktrace-js' */).then(module => {
        const url = `${Config.apiHost}error_logging/`;
        const StackTrace = module.default;
        StackTrace.fromError(error).then(err => {
            StackTrace.report(
                err,
                url,
                {
                    type: 'react-error',
                    url: window.location.href,
                    userId: user && user.email,
                    agent: window.navigator.userAgent,
                    date: new Date(),
                    msg: error.toString()
                }
            );
        });
    });
}