const Config = {
  apiHost: process.env.REST_API_ENDPOINT,
  djangoHost: process.env.DJANGO_HOST,
  publicPath: process.env.PUBLIC_PATH,
  isProduction: process.env.DEPLOY_ENV === 'production' || process.env.DEPLOY_ENV === 'blucher',
  isStaging: process.env.DEPLOY_ENV === 'staging',
  isDevelopment: process.env.DEPLOY_ENV === 'development',
  isBrandEnabled: process.env.BRAND_ENABLED,
  ckeditorTimestamp: process.env.CKEDITOR_TIMESTAMP,
  buildTimestamp: process.env.BUILD_TIMESTAMP,
};

if (Config.isProduction && window.console && window.console.log) {
  window.console.log = () => {};
}

export default Config;
