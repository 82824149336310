import React from 'react';
import ReactDOM from 'react-dom';
import initTranslations from './i18n';
import App from './App';
import ReactGA from 'react-ga4';
import Config from './config';

window.ffShowPageLoading = function () {
  var loadEl = document.getElementById('ff-loading');
  if (loadEl) {
    loadEl.style.display = 'block';
  }
};

window.ffHidePageLoading = function () {
  var loadEl = document.getElementById('ff-loading');
  if (loadEl) {
    loadEl.style.display = 'none';
  }
};

initTranslations();

import('browser-update' /* webpackChunkName: 'browserupdate' */).then((module) => {
  module.default({
    required: {
      e: -4,
      f: -3,
      o: -3,
      s: -1,
      c: -3,
    },
    insecure: true,
    unsupported: true,
    api: 2019.04,
  });
});

if (!Config.isDevelopment && !Config.isStaging && !Config.isBrandEnabled && !location.pathname.endsWith('/edit')) {
  ReactGA.initialize('G-6DK8T5Q3CQ');
}

document.body.classList.remove('loading');
ReactDOM.render(<App />, document.getElementById('app'));
