import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
// import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-xhr-backend';
import Ajax from './common/ajax';
import Config from './config';

export const AVAILABLE_LANGUAGES = [
  { code: 'pt', name: 'Português' },
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
];

export default function initTranslations() {
  i18n
    .use(Backend)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    // .use(LanguageDetector)
    .init({
      backend: {
        // for all available options read the backend's repository readme file
        loadPath: (lngs, namespaces) => {
          return `/i18n/${lngs[0]}/resource.json?t=${Config.buildTimestamp}`;
        },
      },
      fallbackLng: false,
      debug: false, //!Config.isProduction,
      keySeparator: false, // we use content as keys
      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
      },
      react: {
        wait: true,
      },
      detection: {
        order: [
          'navigator',
          'querystring',
          'cookie',
          'localStorage',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
    });

  // Get the user session language to sync with frontend.
  Ajax.get(`${Config.apiHost}get-language/`).done((lang) => {
    if (lang.startsWith('pt')) {
      lang = 'pt';
    }
    i18n.changeLanguage(lang);
  });
}
